import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-salesreports',
  templateUrl: './salesreports.component.html',
  styleUrls: ['./salesreports.component.scss']
})
export class SalesreportsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
