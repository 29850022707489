import { Injectable, Inject } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DatePipe } from '@angular/common';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public access_tocken = "";
  public Server_URL = "http://localhost:55245/";
  public Quotation_Format="Format1";
  public System_mac_address="Not found"
  public Area_Map: boolean = false;
  public NT_Bill_Format = "NT_Format1"
  public Bill_Format = "Format1"
  public Bill_Format1 = "Format6";
  public Vadi_Format = "Format1";
  public check_Unicod: boolean = false;


  public TCS_Enable: boolean = true;
  public Balance_SMS = "false";


  public key_value="";

  public Intrest_Format="Flat"

  public NT_Printer: string = "false";
  public NT_Address: String = "http://localhost:55245/";

  public DB_Vadi_display: boolean = false;
  public Print_Button: boolean = false;

  public Ledger_Update:boolean=true;
  public Cust_Amt: boolean = false;
  public Lfrom = "34";
  public Top = 50;

  public Logo_location = "Top_qubha";
  public Logo_Name = "Qubha";
  public Logo_location_Loin = "Chef";
  public Logo_Sub_Name = "Soft";
  public Phone_No = "9597436220";

  public Qmenu: boolean = true;
  public STamilName: boolean = false;
  public Type_Based_Bill_No: boolean = false;
  public Item_Rate_Update: boolean = true;
  public Check_Stock: boolean = false;
  
  public Image_disp: boolean = false;
  public Ledger_Type = "1";

  public Group_Enable: boolean = false;
  public Print_Bill: boolean = false;
  
  public Save_Print: boolean = true;
  public Save_Print_Mobile: boolean = false;
  public Save_New: boolean = false;

  public  Stockbase_Sales: boolean = false;

  public  mobile_menu: boolean = false;

  
  public from_customer_page:boolean=false;
  public W_height = 0;
  public W_width = 0;

  public SM_Row = [];
  public Sales_Disp_Text2_Visblle: boolean = false;
  public Sales_Disp_Text3_Visblle: boolean = false;
  
  public Loan_Issue_Page="/collection/emi-loan-issue";
  public Add_Receipt_Page="/transaction/add-receipt-entry-two";
  public U_Rights=""
  public Item_Group_ = "1";
  public isadd_Page:boolean=false;
  lenth: number = 0;
  constructor(@Inject(DOCUMENT) private document: Document, private toastr: ToastrService, public datePipe: DatePipe, public http: HttpClient, public router: Router, private _location: Location) {


    if (this.getData()) {

      this.data = JSON.parse(localStorage.getItem('User_Data'));

      try {
        this.Customer_Area = localStorage.getItem('Area');
      } catch { }
      this.Company = "_" + this.data.UM_Company;

    }


    this.W_height = window.innerHeight;

    this.W_width = window.innerWidth;

    var Today_Date = this.datePipe.transform(new Date(), "dd-MMM-yyyy");

    this.T_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.T_Date_Time = this.datePipe.transform(new Date(), "yyyy-MM-ddTHH:mm");


    this.exp_Date = this.datePipe.transform(new Date(), "MM/yy");
    this.Pay_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");



    this.Y_Date = this.datePipe.transform(new Date().getDate() - 1, "dd-MMM-yyyy");
    this.Today_Date = Today_Date;


  
    this.S_From = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.S_To = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.From_Date = { formatted: Today_Date };
    this.To_Date = { formatted: Today_Date };
    this.Date = { formatted: Today_Date };
    this.Date1 = { formatted: Today_Date };


    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    this.load_themes('');



  }


  public openModal: boolean = false;
  Open_Modal(Title, page) {
    this.openModal = true;
    $('#small_Module').modal('show');
  }

  Close_Modal() {
    this.openModal = false;
    $('#small_Module').modal('hide');
  }

  public Header_Disp = "";

  public add_item_name = "/add-items1";
  btndisable: boolean = false;
  isload: boolean = false;
  public Company = "";
  public CREATED_BY = "";
  public Ref_ID = "0";
  public isadd = "0";
  public Edit_Row: any = {};
  public Edit_Row1: any = {};

  public Selected_Row: any = {};
  public Default_ref_ID: string = "Role";
  public URL_Location = "";

  public disp_category:boolean=false;
  public img_visible: boolean = false;

  
  public Date;
  public Date1;
  public From_Date;
  public To_Date;

  public User_Type = "Admin";
  public Document_Type = "Front_Banner";
  public Phone_Pattern = "^((\\+91-?)|0)?[0-9]{10}$";
  public Receipt_Print="false";

  public Side_Menu: boolean = false;
  public Today_Date = "";
  public S_From = "";
  public S_To = "";
  public S_Counter = "All";
  public FS_Date = "2021-04-01";

  public S_Bill_Type = "All";
  public S_Thari_Status = "All";
  public Calibration_Standard ="";
  public Category = "All";

  public S_Pay_Mode = "0";
  public S_Bill_Mode = "All";
  public S_Area = "All"
  public S_Sales_Order_by = "Bill_No";
  public S_Stock = "All"
  public S_Sales_Person = "All"


  public Y_Date = "";
  public data: any = {};
  public Order_Status = "Pending";
  public Search_User = "All";
  public Approval_Status = "Pending";


  public Customer_Area = "All";
  public item_name_disp = "English";

  public T_Date = "";
  public T_Date_Time = "";

  public Pay_Date="";
  public exp_Date="";
  public Open_Customer_Total = 0;
  public Open_Customer_Length = 0;
  public DB_Expense_Total=0;
  public Receipt_Row_Total=0;
  public Emi_Loan_Issue_Total=0;
  public DB_Bank_Row=0;
  public DB_Bank_Total=0;

  public Item_Group="";
  public User_Role_Menu = [
    { Menu: 'User Master', Link: '/user-details' },
    { Menu: 'Role Master', Link: '/role-master' },
    { Menu: 'Role Rights', Link: '/role-permissions' }

  ];

  
  public Menu_Master_GF = ['Module', 'Type', 'Display_Name', 'Route_Link', 'Order_No', 'Icon', 'Remark'];
  public back() {
    this._location.back();
  }


  public datefromat(data) {
    return this.datePipe.transform(data, "yyyy-MM-dd");
  }

  public datetimefromat(data) {
    return this.datePipe.transform(data, "yyyy-MM-ddTHH:mm");
  }
  

  public date_display(data) {
    return this.datePipe.transform(data, "dd-MMM-yyyy");
  }

  public Rights_Name = "";
  public Rights_ID = "";
  public Emp_ID = "";
 

  getData() {
    return JSON.parse(localStorage.getItem('User_Data'));
  }

  F_Row = [];
  get_pos_field(Table, pos) {

    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table).toLowerCase()).filter(e => e.Visible == "True");
    this.F_Row = this.F_Row.filter(e => String(e.Posision).toLowerCase() == String(pos).toLowerCase());
    return this.F_Row.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

  }
  // dateRangeValidator(min: Date, max: Date) {
  //   return control => {
  //     if (!control.value) return null;
 
  //     const dateValue = new Date(control.value);
  
  //     if (min && dateValue < min) {
  //       return { message: 'error message' };
  //     }
  
  //     if (max && dateValue > max) {
  //       return { message: 'error message' };
  //     }
  
  //     null;
  //   }
  // }

  
  Reference_Rows = [];
  get_Reference() {


    this.getc("Api/Master/get_reference").subscribe((res: any) => {
      this.Reference_Rows = JSON.parse(res).record;
    });
  }
  Category_Rows = [];
  get_Category() {


    this.getc("Api/Master/get_category").subscribe((res: any) => {
      this.Category_Rows = JSON.parse(res).record;
    });
  }
  Captain_Rows = [];
  get_captain(data) {


    this.getc("Api/Report/get_captain?status='"+data+"'").subscribe((res: any) => {
      this.Captain_Rows = JSON.parse(res).record;
    });
  }

  Product_Rows = [];
  get_hsn() {


    this.getc("Api/Master/get_hsncode").subscribe((res: any) => {
      this.Product_Rows = JSON.parse(res).record;
    });
  }
  public isEdit: boolean = false;
  public load_from_make_invoice: boolean = false;



  public User_Master_Rows = [];
  get_User_Master() {
    this.getc("Api/Common/get_User_Master").subscribe((res: any) => {
      this.User_Master_Rows = JSON.parse(res).record;
    });
  }


  public Selected_Company: any = {};
  public Selected_Customer: any = {};
  public Current_User: any = {};

  public Order_No = "0";

  public states = [
    "Ariyalur",
    "Chengalpattu",
    "Chennai",
    "Coimbatore",
    "Cuddalore",
    "Dharmapuri",
    "Dindigul",
    "Erode",
    "Kallakurichi",
    "Kanchipuram",
    "Kanyakumari",
    "Karur",
    "Krishnagiri",
    "Madurai",
    "Mayiladuthurai",
    "Nagapattinam",
    "Namakkal",
    "Nilgiris",
    "Perambalur",
    "Pudukkottai",
    "Ramanathapuram",
    "Ranipet",
    "Salem",
    "Sivaganga",
    "Tenkasi",
    "Thanjavur",
    "Theni",
    "Thoothukudi",
    "Tiruchirappalli",
    "Tirunelveli",
    "Tirupattur",
    "Tiruppur",
    "Tiruvallur",
    "Tiruvannamalai",
    "Tiruvarur",
    "Vellore",
    "Viluppuram",
    "Virudhunagar"
  ];



  public Menus_Rows: any = {};
  public Menu_Rows = [];
  public Label_Menu_Rows = [];
  get_Role_Rights(Role) {
    this.get("Api/Setting/get_Menu_for_user?Rights=" + Role).subscribe((res: any) => {
      this.Menu_Rows = JSON.parse(res).record;
      this.Side_Menu = true;
      try {
        this.Label_Menu_Rows = this.Menu_Rows.filter(e => e.Type == "Label");
        this.Label_Menu_Rows = this.Label_Menu_Rows.filter(e => e.Parent_ID == "0");
        this.Label_Menu_Rows.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));
      } catch { }

    });
  }


  get_ref(data) {

    
    try{
    return this.Reference_Rows.filter(e => e.Ref_ID == data);
    }catch{

      return [];
    }
  }
  get_child(parent) {
    var data = this.Menu_Rows.filter(e => e.Parent_ID == parent).sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

    return data;
  }

  get_childs(parent) {
    var data = this.Menu_Rows.filter(e => e.Module == parent).sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

    return data;
  }

  public Menu_data = "";
  public Menu_Master_Rows = [];
  public Parent_Menus = [];
  get_Menu_Master() {
    this.getc("Api/Setting/get_Menu_master").subscribe((res: any) => {
      this.Menu_Master_Rows = JSON.parse(res).record;

      this.Parent_Menus = this.Menu_Master_Rows.filter(e => e.Parent_ID == "0");



    });
  }


  loading = false;


  Num(data) {
    return Number(data).toFixed(2);
  }



  public filter_data="";
  pdfType = "Open";


  get(url) {
    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Authorization': 'Bearer ' + this.access_tocken
    });

//debugger
    return this.http.get(this.Server_URL + "" + url + "&Company=" + this.Company, { headers: reqHeader })
  }

  getc(url) {

    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.access_tocken
    });

    return this.http.get(this.Server_URL + "" + url + "?Company=" + this.Company, { headers: reqHeader });
  }



  d_get(url) {


    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Authorization': 'Bearer ' + this.access_tocken
    });


    return this.http.get(url + "&Company=" + this.Company, { headers: reqHeader })
  }


  public load_page(data) {
    //debugger
    this.router.navigate([data], { replaceUrl: true });
  }

  public load_page1(data) {
    this.router.navigate([data]);
  }


  public Seraial_No_Settings_Rows = [];
  public Perment_Seraial_No_Settings_Rows = [];
  public Reset_Seraial_No_Settings_Row = [];
  public Orderby_Name = "Name";
  get_Seraial_No_Settings() {

    this.loading = true;
    this.isload = true;
    this.Seraial_No_Settings_Rows = [];
    this.get("Api/Setting/get_Seraial_No_Settings?Order_by=" + this.Orderby_Name).subscribe((res: any) => {
      this.Seraial_No_Settings_Rows = JSON.parse(res).record;
      this.Perment_Seraial_No_Settings_Rows = JSON.parse(res).record;
      this.Reset_Seraial_No_Settings_Row = JSON.parse(res).record;
      this.loading = false;
      this.isload = false;
      //   this.Item_Category = [...new Set(this.Item_Master_Rows.map(item => item.Category))];

      // console.log(this.Item_Category);
    });
  }


  public Variable_Settings_Rows = [];
  public Perment_Variable_Settings_Rows = [];
  public Reset_Variable_Settings_Row = [];
  public Variable_Settings_GF = ['ID', 'S_Variable', 'S_Value', 'S_Default'];

  get_Variable_Settings() {

    this.loading = true;
    this.Variable_Settings_Rows = [];
    this.getc("Api/Setting/get_variable_Settings").subscribe((res: any) => {
      this.Variable_Settings_Rows = JSON.parse(res).record;
      this.Perment_Variable_Settings_Rows = JSON.parse(res).record;
      this.Reset_Variable_Settings_Row = JSON.parse(res).record;
      this.loading = false;

    });
  }




  post_data(data, Table) {

    data.Company = this.Company;
    data.Created_by = this.CREATED_BY;
    data.Table_Name = Table;

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    this.isload = true;
    this.http.post(this.Server_URL + 'api/Master/Post_Data', data, { headers: this.headers })
      .subscribe(
        (val: string) => {
          this.isload = false;
          return val;
        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          return "Error Contact Admin";
        });

    //return "Problem"
  }



  public tocken = "";
  get_Token() {



    this.headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
    this.isload = true;
    this.http.post(this.Server_URL + 'token', 'grant_type=password&UserName=admin&Password=admin', { headers: this.headers })
      .subscribe(
        (val) => {
          this.tocken = val['access_token'];

        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          return "Error Contact Admin";
        });

    //return "Problem"
  }



  public length_of(dt) {

    var len = 0;
    try {
      len = dt.filteredValue.length;
      return len
    } catch { }


    if (len == 0) {
      try {
        len = dt.value.length;
      } catch { }
    }

    return len;
  }

  load_themes(data) {
    var d = "default.css";
    try {
      d = localStorage.getItem('Theme');
    } catch { }

    if (d != "default.css" && d != null) {

      this.loadStyle(d);

    }
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  public sum_of(dt, field) {

    var len = 0;
    try {
      len = dt.filteredValue.length;
      return dt.filteredValue.reduce((sum, current) => sum + parseFloat(current[field]), 0);
    } catch { }


    if (len == 0) {
      try {
        len = dt.value.length;
        return dt.value.reduce((sum, current) => sum + parseFloat(current[field]), 0);
      } catch { }
    }

    return len;
  }



  public Excel_Data: any = {
    'ID': '',
    "item": '',
    "Header": '',
    "Footer": '',
    "Left_Data": '',
    "Right_Data": '',
    "Report_Name": ''

  };
  headers;

  export_excel() {

    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {

            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=xls";
            window.open(this.Server_URL + "Home/JsontToExcel?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }
  upload_excel() {

    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {

            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=xls";
            window.open(this.Server_URL + "Report/JsontToExcel_Upload?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }
  //loading = false;

  public Dashboard_Row = [];
  get_Dashboard() {
    this.Dashboard_Row = [];
    this.loading = true;

    this.get("Api/Master/get_dashboard?").subscribe((res: any) => {
      this.Dashboard_Row = JSON.parse(res).record;
      this.loading = false;
    });
  }

  Billprint_PDF(data) {
    debugger
    window.open(this.Server_URL+"home/Print_bill?&UniqNo=" + this.header_Row.sal_bill); 
    }

  File_Name = "Data";

  export_pdf() {

// debugger
    this.Excel_Data.Company_Data = JSON.parse(JSON.stringify(this.Current_User));
    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {
            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=pdf";
            window.open(this.Server_URL + "PDF/Export_Pdf?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }

  get_fields_of(Table) {
 //debugger
    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table).toLowerCase()).filter(e => e.Visible == "True");
    return this.F_Row.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

  }

  get_grid_fields_of(Table) {
    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table).toLowerCase()).filter(e => e.GVisible == "True");
    return this.F_Row.sort((a, b) => Number(a["GOrder"]) - Number((b["GOrder"])));

  }

  public Field_Setting = [];
  get_Field_Setting() {
    this.getc("Api/Setting/get_Field_Setting").subscribe((res: any) => {
      this.Field_Setting = JSON.parse(res).record;

    });

  }
  public Field_Setting_Table_Row = [];
  get_Field_Setting_Table() {
    this.getc("Api/Setting/get_Field_Setting_Table").subscribe((res: any) => {
      this.Field_Setting_Table_Row = JSON.parse(res).record;

    });
  }
  get_Party_details() {
    this.isload = true;
    this.Patry_Row=[];
    this.getc("Api/transaction/get_Customer_Master").subscribe((res: any) => {
    this.Patry_Row = JSON.parse(res).record;
     this.isload = false;
    });
  }
  get_Pur_Details() {
    //debugger
    this.get("Api/Transaction/get_pruchase_details?From=" + this.S_From + "&To=" + this.S_To).subscribe((res: any) => {
      this.Pur_Details_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }
  get_sales_Details() {
    //debugger
   this.get("Api/Transaction/get_sales_details?From=" + this.S_From + "&To=" + this.S_To).subscribe((res: any) => {
   
    this.Pur_Details_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  get_product_Master1() {
      
    this.getc("Api/Master/get_product_Master1").subscribe((res: any) => {
      
      this.Product_Master_Rows = JSON.parse(res).record;
      this.Item_Master_Rows=JSON.parse(res).record;

    });
  }
  
  public Customer_Search_GF = ['Cus_Name','Phone_Number','Area','Aadhar_No','Code'];
  public SLedger_Rows = [];
  get_Ledger_Master() {
    this.isload = true;
    //debugger
   this.get("Api/Master/get_Customer_Master?Status=A&vType=" + this.vType ).subscribe((res: any) => {
    this.isload = false;
      this.Customer_Master_Rows = JSON.parse(res).record;
      this.SLedger_Rows = JSON.parse(res).record;
    });
  }
  // get_Table_Master() {
  //   this.isload = true;
  //   debugger
  //  this.get("Api/Master/get_Table_Master?Status=A&vType=" + this.vType ).subscribe((res: any) => {
  //   this.isload = false;
  //     this.Table_Master_Rows = JSON.parse(res).record;
  //     this.SLedger_Rows = JSON.parse(res).record;
  //   });
  // }
  // get_Item_Master() {
  //   this.isload = true;
  //   //debugger
  //  this.get("Api/Master/get_Item_Master?Status=A&vType=" + this.vType ).subscribe((res: any) => {
  //   this.isload = false;
  //     this.Item_Master_Rows = JSON.parse(res).record;
  //     this.SLedger_Rows = JSON.parse(res).record;
  //   });
  // }
  get_Doctor_Master(Status) {
      
    this.get("Api/Master/get_Doctor_Master?Status=" + Status + "&vType=" + this.vType + "").subscribe((res: any) => {
      
      this.Doctor_Master_Rows = JSON.parse(res).record;

    });
  }
  
  get_doctor(data) {
    try{
      //debugger
      
    return this.Doctor_Master_Rows;
    }catch{
      return [];
    }
  }

  sales_billwise() {
      
     debugger
      this.get("Api/Report/sales_billwise?From=" + this.S_From + "&To=" + this.S_To+ "&counter=" + this.S_Counter).subscribe((res: any) => {
      this.sales_Billwise_Rows = JSON.parse(res).record;
        this.isload = false;
    });
  }
  sales_editsales() {
      
    debugger
     this.get("Api/Report/sales_editsales?From=" + this.S_From + "&To=" + this.S_To+ "&counter=" + this.S_Counter).subscribe((res: any) => {
     this.sales_Billwise_Rows = JSON.parse(res).record;
       this.isload = false;
   });
 }
  
  sales_prodwise() {
      
    // debugger
   
    this.get("Api/Report/sales_prodwise?From=" + this.S_From + "&To=" + this.S_To+ "&counter=" + this.S_Counter).subscribe((res: any) => {
      this.sales_Billwise_Rows = JSON.parse(res).record;
        this.isload = false;
    });
  }

  sales_daywise() {
      
    // debugger
      this.get("Api/Report/sales_daywise?From=" + this.S_From + "&To=" + this.S_To+ "&counter=" + this.S_Counter).subscribe((res: any) => {
      this.sales_Billwise_Rows = JSON.parse(res).record;
      //sales_Billwise_Cols_GF=['Date'];
        this.isload = false;

    });
  }

  sales_hsnwise() {
      
    // debugger
      this.get("Api/Report/sales_hsnwise?From=" + this.S_From + "&To=" + this.S_To+ "&counter=" + this.S_Counter).subscribe((res: any) => {
      this.sales_Billwise_Rows = JSON.parse(res).record;
      //sales_Billwise_Cols_GF=['Date'];
        this.isload = false;

    });
  }

  sales_userwise() {
      
    // debugger
      this.get("Api/Report/sales_userwise?From=" + this.S_From + "&To=" + this.S_To+ "&counter=" + this.S_Counter).subscribe((res: any) => {
      this.sales_Billwise_Rows = JSON.parse(res).record;
      //sales_Billwise_Cols_GF=['Date'];
        this.isload = false;

    });
  }

  get_orders() {
      
     debugger
      this.get("Api/Master/get_dashboard_table").subscribe((res: any) => {
      this.Temp_Order_Row = JSON.parse(res).record;
      //sales_Billwise_Cols_GF=['Date'];
        this.isload = false;

    });
  }
  get_cart_items(data) {
      
    debugger
     this.get("Api/Master/get_dashboard_cartitem?uniqueno= "+data+"&tableno="+data+"").subscribe((res: any) => {
     this.cart_item = JSON.parse(res).record;
     //sales_Billwise_Cols_GF=['Date'];
       this.isload = false;

   });
   
 }
 get_dashboard_kotbill(data) {
     
   debugger
    this.get("Api/Master/get_dashboard_kotbill?uniqueno= "+data+"&tableno="+data+"").subscribe((res: any) => {
    this.cart_item = JSON.parse(res).record;
    //sales_Billwise_Cols_GF=['Date'];
      this.isload = false;

  });
  
}

  // public load_page(data) {
  //   this.router.navigate([data], { replaceUrl: true });
  // }

  public Product_Master_Export=[
    {Field:'pm_name',Name:'Products', Align:''},
    {Field:'pm_mst',Name:'UOM', Align:''},
    {Field:'pm_category',Name:'Category', Align:''},
  ]

  public Billwise_Export=[
    // {Field:'SNo',Name:'S.No', Align:''},
    {Field:'sal_billno',Name:'Bill No', Align:''},
    {Field:'sal_date',Name:'Date           ', Align:''},
    {Field:'sal_counter',Name:'Type             ', Align:''},
    {Field:'sal_total',Name:'Taxable Value', Align:'Right'},
    {Field:'sal_taxamt',Name:'GST(Rs)', Align:'Right'},
    {Field:'sal_netamt',Name:'Net Amount', Align:'Right'},
    {Field:'sal_paymode',Name:'Pay Mode', Align:''},
  ]

  public Prodwise_Export=[
    // {Field:'SNo',Name:'S.No', Align:''},
    {Field:'sal_item',Name:'Item Name     ', Align:''},
    {Field:'item_category',Name:'Category    ', Align:''},
    {Field:'item_hsncode',Name:'HSN Code', Align:''},
    {Field:'qty',Name:'Qty    ', Align:'Right'},
    {Field:'Net',Name:'Net Amount', Align:'Right'},
    //{Field:'sal_paymode',Name:'Pay Mode', Align:''},
  ]

  public Payuserwise_Export=[
    // {Field:'SNo',Name:'S.No', Align:''},
    {Field:'sal_updateby',Name:'User Name     ', Align:''},
    {Field:'sal_counter',Name:'Category    ', Align:''},
    {Field:'billcount',Name:'No.of Bills', Align:'Right'},
    {Field:'tablecount',Name:'No.of Table', Align:'Right'},
    {Field:'qty',Name:'Qty     ', Align:'Right'},
    {Field:'cash',Name:'Cash     ', Align:'Right'},
    {Field:'card',Name:'Card     ', Align:'Right'},
    {Field:'Net',Name:'Net Amount', Align:'Right'},
    //{Field:'sal_paymode',Name:'Pay Mode', Align:''},
  ]

  public HSNwise_Export=[
    // {Field:'SNo',Name:'S.No', Align:''},
    {Field:'item_hsncode',Name:'HSN Code', Align:''},
    {Field:'qty',Name:'Qty    ', Align:'Right'},
    {Field:'Taxable',Name:'Taxable Value', Align:'Right'},
    {Field:'CGST',Name:'GST(Rs) ', Align:'Right'},
    // {Field:'CGST',Name:'SGST(Rs) ', Align:'Right'},
    {Field:'Net',Name:'Net Amount', Align:'Right'},
  ]
  
  public Daywise_Export=[
    // {Field:'SNo',Name:'S.No', Align:''},
    {Field:'sal_date',Name:'Date    ', Align:''},
    {Field:'Taxable',Name:'Taxable Value', Align:'Right'},
    {Field:'CGST',Name:'CGST(Rs) ', Align:'Right'},
    {Field:'CGST',Name:'SGST(Rs) ', Align:'Right'},
    {Field:'Net',Name:'Net Amount', Align:'Right'},
    //{Field:'sal_paymode',Name:'Pay Mode', Align:''},
  ]

  public SalesReport_Menu = [
    { Menu: 'Bill-Wise', Link: '/salesreports/billwise' },
    { Menu: 'Product-Wise', Link: '/salesreports/prodwise' },
    { Menu: 'Day-Wise', Link: '/salesreports/daywise' },
    { Menu: 'HSN-Wise', Link: '/salesreports/hsnwise' },
    { Menu: 'Overall Report', Link: '/salesreports/daywise' }

  ];
  public sales_Billwise_Rows=[];
  public sales_Userwise_Rows=[];
  public sales_Billwise_Cols_GF=['sal_billno','sal_uniqueno','sal_party','sal_cellno'];
  public sales_prodwise_Cols_GF=['sal_item','sal_uom','sal_category','sal_drugtype','sal_hsncode'];

  public Captain_Master_col_GF=['cap_full_name','cap_user_name','cap_rights'];
  public Customer_Master_col_GF=['Cus_Name','Cus_City','Cus_ContactNo'];
  public Item_Master_col_GF=['item_name','item_category','item_code'];
  public Table_Master_col_GF=['tbl_name','tbl_waiter'];
  public Patient_Master_col_GF=['Cus_Name','Cus_City','Cus_ContactNo'];
  public Doctor_Master_col_GF=['dm_name','dm_cellNo','dm_city'];
  public Customer_Master_Rows=[];
  public Table_Master_Rows=[];
  public Item_Master_Rows=[];
  public Patient_Master_Rows=[];
  //Dashboard
  public Stcok_Row=[];
  public Temp_Order_Row=[];

  public kot_billno="";
  public Customer_details="";
  public vType="";
  public Tax_Type="";
  public Customer_Order_by="";
  public Product_Order_By=['Product','pm_combination','pm_category','pm_mst'];
  public Product_Master_Rows=[];
  public Product_Master_Cols_GF=['pm_name','pm_combination','pm_category','pm_mst'];
  public Doctor_Master_Rows=[];

//Purchase
  public Pur_Details_GF=[];
  public Patry_Row=[];
  public Details_Row=[];
  public header_Row: any = {}
  public GST_Code = "";
  // public Item_Master_Rows = [];
  public Sales_Temp_Row: any = {}; 
  public Item_Orderby_Name = "Category,Item_Name";
  public Perment_Item_Master_Row = [];
  public Reset_Item_Master_Row = [];
  public Item_Row_Cout = 0;
  public Item_Master_RM_Rows = [];
  public Supplierwise_Track_GF = ['Supplier', 'S_Value', 'Profit', 'Stock_Value', 'P_Value'];  
  public cart_item = []; 
  public Project_Row = [];
  public GoDown = [];
  public Pur_Details_Row = [];
  public item = "";
  public lable_ = "";
  public Filter_Type = "contain";
  public fmdate = "contain";

  //Sales
  
  public Sal_Details_GF=[];
}
