import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  public addValidation: boolean = false;
  public add: any = {};
  headers;
  data;
  isadd = "0";
  public btndisable:boolean =false;
  constructor(private _location: Location, public appservice: AppService, private toastr: ToastrService, private http: HttpClient, private router: Router, private route: ActivatedRoute) { }

  Back() {
    this._location.back();
  }
  public Area_Row = [];
  get_Area() {

    //this.Area_Row = this.appservice.Area_Row;
   
  }

  Last_Order=0;

  get_order()
  {
    
    try{
    //this.Last_Order=this.appservice.Ledger_Master_Rows.filter(e=>e.Area==this.add.Area).length;

    this.add.Order=this.Last_Order+1;
    this.appservice.Customer_Area=this.add.Area;
    }catch{}
  }

  add_area()
  {
    this.appservice.Ref_ID='Area';
    this.router.navigate(['add-reference']);
  }
  addData(f) {
    f.form.value.image=null;
  
  f.form.value.Company = this.appservice.Company;
  
  if (f.invalid === true)
    this.addValidation = true;
  else {
    this.addValidation = false;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    this.btndisable=true;
    this.http.post(this.appservice.Server_URL + 'api/master/insert_Ledger_Master', f.form.value, { headers: this.headers })
      .subscribe(
        (val: string) => {
          this.btndisable=false;
          if (val == "True") {
            this.toastr.success("Details Salved Success", 'Msg');
            // this.appservice.get_Ledger_Master();
            // this.appservice.from_customer=true;
            // this.appservice.from_customer_Code=this.add.Code;
            // this.Clear();
            this._location.back();
            
          }
          else {
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
        });
  }
}
  ngOnInit() { }

}
