import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { SidebarComponent } from './components/layouts/sidebar/sidebar.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard/dashboard.component';
// import { AnalyticsComponent } from './components/pages/dashboard/analytics/analytics.component';
import { EcommerceComponent } from './components/pages/dashboard/ecommerce/ecommerce.component';
import { AuthenticationComponent } from './components/pages/authentication/authentication/authentication.component';
import { LoginComponent } from './components/pages/authentication/login/login.component';
import { LoginWithImageComponent } from './components/pages/authentication/login-with-image/login-with-image.component';
import { RegisterComponent } from './components/pages/authentication/register/register.component';
import { RegisterWithImageComponent } from './components/pages/authentication/register-with-image/register-with-image.component';
import { ForgotPasswordComponent } from './components/pages/authentication/forgot-password/forgot-password.component';
import { ForgotPasswordWithImageComponent } from './components/pages/authentication/forgot-password-with-image/forgot-password-with-image.component';
import { ResetPasswordComponent } from './components/pages/authentication/reset-password/reset-password.component';
import { ResetPasswordWithImageComponent } from './components/pages/authentication/reset-password-with-image/reset-password-with-image.component';



import { ReferenceDetailsComponent } from './master/reference-details/reference-details.component';
import { ReferencMasterComponent } from './master/referenc-master/referenc-master.component';

import {FileUploadModule} from 'primeng/fileupload';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {TableModule} from 'primeng/table';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// import { CustomerDetailsComponent } from './master/customer-details/customer-details.component';
// import { CustomersComponent } from './master/customers/customers.component';
// import { AddBankComponent } from './master/add-bank/add-bank.component';
// import { BankDetailsComponent } from './master/bank-details/bank-details.component';
// import { AddItemComponent } from './master/add-item/add-item.component';
// import { ItemMasterComponent } from './master/item-master/item-master.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import { ToastrService, ToastrModule } from 'ngx-toastr';
import { UserMasterComponent } from './master/user-master/user-master.component';
import { AddUserComponent } from './master/add-user/add-user.component';
import { MyDatePickerModule } from 'mydatepicker';

import { RoleRolesPermissionsComponent } from './role-roles-permissions/role-roles-permissions.component';
// import { SearchCustomerComponent } from './search-customer/search-customer.component';
import { UpdateCompanyDetailsComponent } from './update-company-details/update-company-details.component';
import { NotificatonComponent } from './notificaton/notificaton.component';
import { AddItem1Component } from './master/add-item1/add-item1.component';
// import { AddCustomerAutoComponent } from './master/add-customer-auto/add-customer-auto.component';
import { RoleMasterComponent } from './role-master/role-master.component';
import { SettingDashboardComponent } from './setting-dashboard/setting-dashboard.component';
// import { CutomerSearchComponent } from './cutomer-search/cutomer-search.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
// import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component';

import { InvalidLicenceComponent } from './invalid-licence/invalid-licence.component';
 import { ProductDetailsComponent } from './master/product-details/product-details.component';

 import { AddRoleComponent } from './add-role/add-role.component';
 import { CustomerSearchComponent } from './customer-search/customer-search.component';
// import { BillwisesalesComponent } from './salesReport/billwisesales/billwisesales.component';
// import { SalesReportComponent } from './sales-report/sales-report.component';
// import { BillwiseSalesComponent } from './sales-report/billwise-sales/billwise-sales.component';
// import { SalesBillwiseComponent } from './salesReport/sales-billwise/sales-billwise.component';
import { SalesreportsComponent } from './salesreports/salesreports.component';




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    // AnalyticsComponent,
    EcommerceComponent,
    AuthenticationComponent,
    LoginComponent,
    LoginWithImageComponent,
    RegisterComponent,
    RegisterWithImageComponent,
    ForgotPasswordComponent,
    ForgotPasswordWithImageComponent,
    ResetPasswordComponent,
    ResetPasswordWithImageComponent,
    ReferenceDetailsComponent,
    ReferencMasterComponent,
    // CustomerDetailsComponent,
    // CustomersComponent,
    // AddBankComponent,
    // BankDetailsComponent,
    // AddItemComponent,
    // ItemMasterComponent,
    UserMasterComponent,
    AddUserComponent,
    UpdateCompanyDetailsComponent,
    RoleRolesPermissionsComponent,
    // SearchCustomerComponent,
    NotificatonComponent,
    AddItem1Component,
    // AddCustomerAutoComponent,
    RoleMasterComponent,
    SettingDashboardComponent,
    // CutomerSearchComponent,
    ChangePasswordComponent,
    UserProfileComponent,
    // SalesDashboardComponent,
    InvalidLicenceComponent
    ,ProductDetailsComponent,AddRoleComponent,
    CustomerSearchComponent,
    // BillwisesalesComponent,
    // SalesReportComponent,
    // BillwiseSalesComponent,
    // SalesBillwiseComponent,
    SalesreportsComponent
    
   
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DropdownModule,
    RouterModule ,
    FormsModule ,
    ReactiveFormsModule,
    
    HttpClientModule,
    TableModule,
    FileUploadModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-bottom-right'
    }),
    
    ConfirmDialogModule,
    MyDatePickerModule,
   
  ],

 
  providers: [DatePipe,
    ConfirmationService,
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


 export  interface Order_DB_ {
  Pending: number;
  Delivery: number;
  T_Pending: number;
  T_Orders: number;
  Y_Orders: number;
  Y_Pending: number;
  Y_Delivery: number
}
